import * as React from "react";
import {Link, PageProps} from "gatsby";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Breadcrumb } from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import { ProductBar } from "../../../components/product-bar/product-bar";
import { Level } from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import blogImage from "../../../images/kuru-neden.png";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CoughWhatPage: React.FC<PageProps> = ({ location }: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: "Kuru Öksürük Nedir, Nedenleri Nelerdir?",
        description:
          "Kuru öksürük nedir, kuru öksürüğün nedenleri nelerdir? Kuru öksürük ve nedenleri hakkında daha detaylı bilgiye sahip olmak için sayfamızı ziyaret edin!",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Kuru Öksürük Nedir, Nedenleri Nelerdir?","item": "${location.href}"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Kuru Öksürük Nedir, Nedenleri Nelerdir?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2021-09-01",
              "dateModified": "2021-09-01"
            }
          `}
        </script>
        <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Kuru Öksürük Nedir?","acceptedAnswer":{"@type":"Answer","text":"Eğer öksürükle beraber boğazda balgam (tıpta mukus olarak da bilinir) bulunmuyorsa, buna kuru öksürük denir."}},{"@type":"Question","name":"Kuru Öksürük Nedenleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Kuru öksürük oldukça rahatsız edici olabilir. Genellikle kış aylarında veya soğuk havalarda ortaya çıkar. Kuru öksürüğün en çok bilinen nedenleri arasında virüsler sebebiyle oluşan solunum yolu enfeksiyonları gelir."}},{"@type":"Question","name":"Geçmeyen Kuru Öksürük Nedir, Geçmeyen Kuru Öksürükte Neye Dikkat Edilmelidir?","acceptedAnswer":{"@type":"Answer","text":"Soğuk algınlığına bağlı öksürük 3 haftaya kadar sürebilir. Öksürüğün kendiliğinden geçmesi beklenilebilir ancak her öksürük kendiliğinden geçmeyebilir. Özellikle solunum yolu enfeksiyonlarına bağlı olarak görülebilen öksürükten en fazla etkilenenler küçük çocuklardır."}},{"@type":"Question","name":"Kuru Öksürüğe Ne İyi Gelir?","acceptedAnswer":{"@type":"Answer","text":"Bulunulan ortamın havası kuru veya nemsiz ise havalandırmak veya ortamın nem dengesine dikkat etmek, sigara veya tütün ürünlerinden uzak durmak ve bu ürünlerin kullanıldığı ortamlarda bulunmamak kuru öksürükten kaçınmak için alınabilecek önlemler arasındadır. "}},{"@type":"Question","name":"Bisolnatur Nasıl Kullanılır?","acceptedAnswer":{"@type":"Answer","text":"Bisolnatur’ü kullanmadan önce kullanım kılavuzu dikkatle okunmalıdır. Doğru şekilde kullanım için, şişe açılmadan önce iyice çalkalanmalı ve kutu ile birlikte verilen kaşık kullanılmalıdır."}}]}`}</script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className={classNames(styles.header)}>
          <StaticImage
            className={styles.image}
            src="../../../images/kuru-neden.png"
            alt="Hapşuran çocuk görseli"
            width={1680}
            height={450}
          />
          <div className={styles.filter} />
          <Container>
            <Heading>Kuru Öksürük Nedir, Nedenleri Nelerdir?</Heading>
            <ul>
              <li>
                <AnchorLink className="blue" to="#oksuruk" title="Öksürük">
                  Öksürük
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kuru-oksuruk-nedir"
                  title="Kuru Öksürük Nedir?"
                >
                  Kuru Öksürük Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kuru-oksuruk-nedenleri-nelerdir"
                  title="Kuru Öksürük Nedenleri Nelerdir?"
                >
                  Kuru Öksürük Nedenleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#gecmeyen-kuru-oksuruk-nedir"
                  title="Geçmeyen Kuru Öksürük Nedir, Geçmeyen Kuru Öksürükte Neye
                  Dikkat Edilmelidir?"
                >
                  Geçmeyen Kuru Öksürük Nedir, Geçmeyen Kuru Öksürükte Neye
                  Dikkat Edilmelidir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#kuru-oksuruge-ne-iyi-gelir"
                  title="Kuru Öksürüğe Ne İyi Gelir?"
                >
                  Kuru Öksürüğe Ne İyi Gelir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bisolnatur"
                  title="Bisolnatur"
                >
                  Bisolnatur
                </AnchorLink>
              </li>
              <li className="blue" onClick={() => scrollTo("#kaynakca")}>
                <AnchorLink className="blue" to="#kaynakca" title="Kaynakça">
                  Kaynakça
                </AnchorLink>
              </li>
            </ul>
          </Container>
        </div>
      </Container>
      <Container>
        <div className={styles.holder}>
          <section className={styles.whatHolder}>
            <Level>
              <Heading id="oksuruk" className="orange underline">
                Öksürük
              </Heading>
              <p>
                <Link className="blue underline" to={"/oksuruk/oksuruk-nedir/"}>
                  Öksürük;
                </Link>{" "}
                tıpkı göz kapaklarının açılıp kapanması gibi istemli ya da
                istemsiz olarak gerçekleşebilen, vücudun kendisini korumak için
                için başvurduğu reflekslerden birisidir. Solunum yolunda bulunan
                yabancı maddeleri, tozu, mikropları veya virüsleri vücut dışına
                atmaya yardımcı olur.
                <br />
                <br />
                Öksürük aynı zamanda vücudumuzla ilgili bir sinyal vericidir.
                Örneğin, solunum yolu enfeksiyonlarına bağlı solunum yollarının
                tahriş olması öksürüğe sebep olabilir. Öksürük, balgamlı
                olabileceği gibi balgamsız da olabilir.
              </p>
              <Heading id="kuru-oksuruk-nedir" className="orange underline">
                Kuru Öksürük Nedir?
              </Heading>
              <p>
                Eğer öksürükle beraber boğazda balgam (tıpta mukus olarak da
                bilinir) bulunmuyorsa, buna kuru öksürük denir. Tozlu, havasız
                ortamlarda bulunmak ve sigara dumanına maruz kalmak da kuru
                öksürüğü tetikleyebilir.
              </p>
              <Heading
                id="kuru-oksuruk-nedenleri-nelerdir"
                className="orange underline"
              >
                Kuru Öksürük Nedenleri Nelerdir?
              </Heading>
              <p>
                Kuru öksürük oldukça rahatsız edici olabilir. Genellikle kış
                aylarında veya soğuk havalarda ortaya çıkar. Kuru öksürüğün en
                çok bilinen nedenleri arasında virüsler sebebiyle oluşan solunum
                yolu enfeksiyonları gelir. Diğer sebepler arasında ise alerji,
                havada bulunan ve boğazı irrite edebilecek partiküller ve uzun
                süre kuru hava solumak bulunur. Bazı ilaçların yan etkileri de
                kuru öksürüğe sebep olabilir. Tüm dünyayı etkisi altına alan
                COVID-19’un semptomlarından birisi de kuru öksürüktür.
              </p>
              <Heading
                id="gecmeyen-kuru-oksuruk-nedir"
                className="orange underline"
              >
                Geçmeyen Kuru Öksürük Nedir, Geçmeyen Kuru Öksürükte Neye Dikkat
                Edilmelidir?
              </Heading>
              <p>
                Soğuk algınlığına bağlı öksürük 3 haftaya kadar sürebilir.
                Öksürüğün kendiliğinden geçmesi beklenilebilir ancak her öksürük
                kendiliğinden geçmeyebilir. Özellikle solunum yolu
                enfeksiyonlarına bağlı olarak görülebilen öksürükten en fazla
                etkilenenler küçük çocuklardır.
                <br />
                <br />
                Enfeksiyona bağlı öksürük, ortaya çıktığında haftalarca
                sürebilir ve birçok olumsuz sonuca yol açabilir. Yoğun öksürük
                nöbetleri gece uyumayı da engelleyebilir. Böyle durumlarda,
                vakit kaybetmeden tedaviye başlanmalıdır.{" "}
                <Link
                  className="blue underline"
                  to={"/oksuruk/kuru-oksuruk-nasil-gecer/"}
                >
                  "Kuru Öksürük Nasıl Geçer?"
                </Link>{" "}
                sayfamızdan daha fazla bilgi alabilirsiniz.
              </p>

              <StaticImage
                className={styles.imageFirst}
                src="../../../images/sickness-cough-coughing-sick-day-sick-child-catch-a-cold-common-cold-getting-sick-coronavirus_t20_2WOz9Z@3x.jpg"
                alt="Hapşuran çocuk görseli"
                width={792}
                height={348}
              />
              <Heading
                id="kuru-oksuruge-ne-iyi-gelir"
                className="orange underline"
              >
                Kuru Öksürüğe Ne İyi Gelir?
              </Heading>
              <p>
                Bulunulan ortamın havası kuru veya nemsiz ise havalandırmak veya
                ortamın nem dengesine dikkat etmek, sigara veya tütün
                ürünlerinden uzak durmak ve bu ürünlerin kullanıldığı ortamlarda
                bulunmamak kuru öksürükten kaçınmak için alınabilecek önlemler
                arasındadır. Kuru öksürüğün ortaya çıktığı ve rahatsızlık
                oluşturduğu durumlarda, boğazı rahatlatmak için bal
                tüketilebilir. Bal, tek başına tüketildiğinde bile öksürüğü
                rahatlatıcı etkiye sahiptir. Bitki çayları ile de tahriş olan
                boğaz rahatlatılabilir.
              </p>
            </Level>
          </section>
          <section id="bisolnatur" className={styles.bisolnatur}>
            <Level>
              <Heading>Bisolnatur ile Öksürüğünü Rahatlat</Heading>
              <div className={classNames("flexbox", styles.productHolder)}>
                <StaticImage
                  className={styles.image}
                  src="../../../images/bisolnatur-urun-yeni.png"
                  alt="Bisolnatur ürün görseli"
                  width={282}
                  height={282}
                />
                <div className={styles.content}>
                  <p>
                    Bisolnatur, doğal içeriğiyle boğazda koruyucu bir tabaka
                    oluşturarak, boğazın tahriş olmasını engeller ve kuru
                    öksürüğü rahatlatır. Aynı zamanda, balgamlı öksürükte de
                    balgamı yumuşatarak kolayca atılmasını sağlar.
                  </p>
                </div>
              </div>
              <Level>
                <Heading>Bisolnatur’ün İçeriği</Heading>
                <p>
                  Doğal içerikli Bisolnatur, patentli Poliflav M.A. kompleksi ve
                  bal içerir.
                </p>
                <div className={classNames("flexbox", styles.productHolder)}>
                  <StaticImage
                    className={styles.image}
                    src="../../../images/GettyImages-627904344-Sinirotu.webp"
                    alt="Bisolnatur ürün görseli"
                    width={282}
                    height={188}
                  />
                  <div className={styles.content}>
                    <Level>
                      <Heading>Poliflav M.A. kompleksi</Heading>
                    </Level>
                    <p>
                      Mızrak yapraklı sinirotundan elde edilen polisakkarit
                      fraksiyonu ile kekikten elde edilen flavonoid
                      fraksiyonundan oluşur.
                    </p>
                  </div>
                </div>
                <div className={classNames("flexbox", styles.productHolder)}>
                  <StaticImage
                    className={styles.image}
                    src="../../../images/GettyImages-157394621-Bal.webp"
                    alt="Bisolnatur ürün görseli"
                    width={282}
                    height={188}
                  />
                  <div className={styles.content}>
                    <Level>
                      <Heading>Bal</Heading>
                    </Level>
                    <p>
                      Doğadaki en eski şifa kaynaklarından biri olan bal,
                      solunum yollarında kayganlaştırıcı bir etkiye sahiptir.
                      Öksürüğün sebep olduğu boğaz tahrişini rahatlatır.
                    </p>
                  </div>
                </div>
                <Heading>Bisolnatur’ün Özellikleri</Heading>
                <p>
                  Doğal içeriğiyle öksürüğü rahatlatan Bisolnatur renklendirici,
                  koruyucu, tatlandırıcı, paraben gibi hiçbir katkı maddesi
                  içermez. Aynı zamanda, glüten de içermediği için çölyak
                  hastaları tarafından güvenle kullanılabilir.
                  <br />
                  <br />
                  Bisolnatur içerdiği doğal şeftali, limon ve portakal aromaları
                  ile hoş bir tada sahiptir. T.C Sağlık Bakanlığı TİTCK’na*
                  kayıtlı olan Bisolnatur, 1 yaşından büyük herkes tarafından
                  kullanılabilir. Hamilelik ve emzirme dönemlerinde ise doktora
                  danışılarak kullanılabilir.
                  <br />
                  <br />
                  *Türkiye İlaç Tıbbi Cihaz Kurumu
                </p>
                <Heading>Bisolnatur Nasıl Kullanılır?</Heading>
                <p>
                  Bisolnatur’u kullanmadan önce kullanım kılavuzu dikkatle
                  okunmalıdır. Doğru şekilde kullanım için, şişe açılmadan önce
                  iyice çalkalanmalı ve kutu ile birlikte verilen kaşık
                  kullanılmalıdır.
                  <br />
                  <br />
                  <b>
                    Hamile ve emzirenler doktora danışarak Bisolnatur’ü
                    kullanabilir.
                  </b>
                  <br />
                  <br />
                  Tüketiciler için önerilen kullanım dozları şu şekildedir (bir
                  doz=bir ölçü kaşığı):
                </p>
                <ul>
                  <li className="blue">
                    <b>1-6 yaş arasındaki çocuklarda</b>, 1 ölçü kaşığı (5 ml)
                    olmak üzere günde iki doz
                  </li>
                  <li className="blue">
                    <b>6 yaş ve üzerindeki çocuklarda</b> 2 ölçü kaşığı (10 ml)
                    olmak üzere günde iki doz
                  </li>
                  <li className="blue">
                    <b>Yetişkinlerde</b> ise 2 ölçü kaşığı (10 ml) olmak üzere
                    günde iki doz (Aynı zamanda, yetişkinlerde, tüketicinin
                    gerekli gördüğü durumlarda kısa aralıklarla 4 defaya kadar
                    kullanılması mümkündür.)
                  </li>
                </ul>
                <p>
                  Sağlık Bakanlığı’na kayıtlı olan Bisolnatur’ün 1 yaş üzeri
                  çocuklar için kullanımı uygundur. Yetişkinlerde veya
                  çocuklarda uyku hâline sebep olmaz. Bisolnatur’ün
                  kullanımında, öksürük belirtilerinin bir hafta içinde
                  iyileşmemesi hâlinde doktora veya eczacıya başvurulması
                  önerilir.
                </p>
                <Heading>Bisolnatur Saklama Koşulları</Heading>
                <p>
                  Bisolnatur; serin, kuru ve çocukların ulaşamayacağı bir yerde
                  saklanmalıdır. Direkt olarak güneş ışığına maruz
                  bırakılmamalıdır. Şişesi açıldıktan sonra 3 ay içinde
                  tüketilmelidir.
                </p>
                <Heading id="kaynakca">Kaynakça</Heading>
                <p>
                  https://www.nhsinform.scot/illnesses-and-conditions/lungs-and-airways/cough
                </p>
                <p>
                  https://www.healthline.com/health/dry-cough
                </p>
                <p>
                  https://www.medicalnewstoday.com/articles/324912
                </p>
                <p>
                  https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4298918/
                </p>
                <p>
                  https://toraks.org.tr/site/sf/wgmf/rfic/15125ff5f6335aaa991ff9c7eb9b3da31342596692d7d327f6fea00143831536.pdf
                </p>
              </Level>
            </Level>
          </section>
        </div>
        <div className="date">
          <p>Yayınlama Tarihi: 2021-09-01</p>
          <p>Son güncellenme Tarihi: 2021-09-01</p>
        </div>
      </Container>
      <ProductBar />
    </Layout>
  );
};
export default CoughWhatPage;
