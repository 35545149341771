// extracted by mini-css-extract-plugin
export var bisolnatur = "styles-module--bisolnatur--iBuqt";
export var breadcrumb = "styles-module--breadcrumb--9klUd";
export var content = "styles-module--content--4vh45";
export var filter = "styles-module--filter--idxWC";
export var header = "styles-module--header--sUAXj";
export var holder = "styles-module--holder--5K+vv";
export var image = "styles-module--image--4lF7a";
export var imageFirst = "styles-module--imageFirst--MzhFs";
export var productHolder = "styles-module--productHolder--ELAfq";
export var whatHolder = "styles-module--whatHolder--O8TAV";